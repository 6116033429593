import React, { ReactElement } from "react";

import style from "./style.module.scss";
import dynamic from "next/dynamic";
const WhyToInsure = dynamic(
    () => import("@components/insureyourcar/whyToInsure"),
);

export default function HomeComponentsList(): ReactElement {
    const SellingBox = dynamic(
        () => import("@components/insureyourcar/sellingBox"),
    );

    return (
        <div className={`${style.bg}`}>
            <WhyToInsure />
            <SellingBox />
        </div>
    );
}
